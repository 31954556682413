// src/axios.js
import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://cs.hkd5.com/prod-api',
  // baseURL: 'http://localhost:8081', 
  timeout: 100000,
});

export default instance;
